<template>
    <div v-if="message" class="message_desc truncate">
        <div v-if="chat.last_message.message_author" class="truncate flex items-center">
            <div v-if="chat.is_public" class="mr-1">
                {{ messageAuthor }}:
            </div>
            <span v-if="message.message_reply" class="truncate mr-1 blue_color">
                <i class="fi fi-rr-undo" />
            </span>
            <div v-if="messageImages.length" class="message_images">
                <div v-for="image in messageImages" :key="image.id" class="message_images__image">
                    <img :data-src="image.path" class="lazyload" :key="image.id" />
                </div>
            </div>
            <span v-if="!messageFiles && messageImages.length && !lastMessageReplace.length" class="truncate">
                {{ messageImagesCount }}
            </span>
            <span v-if="messageFiles" class="truncate">
                {{ messageFiles }}<span v-if="lastMessageReplace.length" class="mr-1">,</span>
            </span>
            <span class="truncate">
                {{ lastMessageReplace }}
            </span>
        </div>
        <span v-else v-html="chat.last_message.text" />
    </div>
</template>

<script>
import { declOfNum } from '../utils'
export default {
    props: {
        chat: {
            type: Object,
            required: true
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        message() {
            return this.chat.last_message
        },
        messageAuthor() {
            if(this.user) {
                if(this.user.id === this.message.message_author.id) {
                    return 'Вы'
                } else {
                    const n = this.message.message_author.full_name.split(' ')
                    return `${n[0]}${n[1] ? ' ' + n[1].charAt(0).toUpperCase() : ''}${n[2] ? '.' + n[2].charAt(0).toUpperCase() + '.' : ''}`
                }
            }
            return ''
        },
        messageImages() {
            if(this.message.attachments?.length) {
                const files = this.message.attachments.filter(f => f.is_image)
                if(files.length > 4) {
                    return files.splice(0, 4)
                }

                return files
            }

            return []
        },
        messageImagesCount() {
            if(this.message.attachments?.length) {
                const file = this.message.attachments.filter(f => f.is_image)
                if(file.length) {
                    return file.length + ' '
                        + declOfNum(file.length,
                            ['Фотография', 'Фотографии', 'Фотографий'])
                }
                
                return null
            }
            
            return []
        },
        messageFiles() {
            if(this.message.attachments?.length) {
                const file = this.message.attachments.filter(f => !f.is_image)
                if(file.length) {
                    return file.length + ' '
                        + declOfNum(file.length,
                            ['Файл', 'Файла', 'Файлов'])
                }
            }
            
            return null
        },
        lastMessageReplace() {
            let mess = this.chat.last_message
            let res =  this.chat.last_message.text.toString().replace(/[<]br[^>]*[>]/gi," ")

            // Share
            if(res.trim().length === 0 && mess.share){
                if(mess.share.type === "tasks.TaskModel" || !mess.share?.time_interval) res = `"Задача #${mess.share.counter}"`
                if(mess.share.type === "comments.CommentModel" || mess.share.type === "comments") res = `"Комментарий: ${mess.share.text}"`
                if(mess.share.type === "meetings.PlannedMeetingModel") res = `"Собрание: ${mess.share.name}"`
                if(mess.share.type === "workgroups.WorkgroupModel" || mess.share.type === "workgroups.WorkGroupModel") res = mess.shareюis_project ? `"Проект: ${mess.share.name}"` : `"Команда: ${mess.share.name}"`
                if(mess.share.type === "tasks.TaskSprintModel" || mess.share?.time_interval) res = `"Спринт: ${mess.share.name}"`
                if(mess.share.type === "event_calendar.EventCalendarModel" || mess.share.type === "event") res = `"Событие: ${mess.share.name}"`
                if(mess.share.type === "crm.GoodsOrderModel") res = `"Заказ: ${mess.share.counter}"`
                if(mess.share.type === "tickets.TicketModel") res = `"База: ${mess.share.config_1c.name}"`
            }
            return res
        }
    }
}
</script>

<style lang="scss" scoped>
.message_images{
    display: flex;
    align-items: center;
    margin-right: 5px;
    &__image{
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 5px;
        background: rgba(115, 115, 115, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border2);
        &:not(:last-child){
            margin-right: 3px;
        }
        img{
            object-fit: cover;
            vertical-align: middle;
            -o-object-fit: cover;
            max-width: 100%;
            opacity: 0;
            transition: opacity 0.05s ease-in-out;
            &.lazyloaded{
                opacity: 1;
            }
        }
    }
}
</style>