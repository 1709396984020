<template>
    <a-popover 
        trigger="click" 
        destroyTooltipOnHide
        :overlayStyle="{
            zIndex: 99999999
        }"
        overlayClassName="emoji_popover">
        <template slot="content">
            <div class="emoji_list">
                <a-button 
                    v-for="(item, index) in emojiList" 
                    type="ui"
                    ghost
                    shape="circle"
                    :key="index" 
                    @click="selectEmoji(item)">
                    {{ item.unicode }}
                </a-button>
            </div>
        </template>
        <a-button 
            type="ui"
            ghost
            :size="size"
            icon="fi-rr-smile" 
            shape="circle"
            flaticon />
    </a-popover>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            emojiList: [
                {
                    unicode: '👍'
                },
                {
                    unicode: '👎'
                },
                {
                    unicode: '😭'
                },
                {
                    unicode: '😕'
                },
                {
                    unicode: '😊'
                },
                {
                    unicode: '😍'
                },
                {
                    unicode: '😄'
                },
                {
                    unicode: '😉'
                },
                {
                    unicode: '😘'
                },
                {
                    unicode: '😳'
                },
                {
                    unicode: '😡'
                },
                {
                    unicode: '😎'
                },
                {
                    unicode: '😴'
                },
                {
                    unicode: '😦'
                },
                {
                    unicode: '😰'
                }
            ]
        }
    },
    methods: {
        selectEmoji(item) {
            this.$emit('click', {
                detail: {
                    unicode: item.unicode
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.emoji_list{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    min-width: 164px;
    &::v-deep{
        .ant-btn{
            font-size: 22px;
        }
    }
}
</style>