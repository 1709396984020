<template>
    <div class="message_files mt-2" :class="message.attachments.length > 1 && 'grid gap-1.5 grid-cols-2'">
        <div 
            class="file_list_item" 
            v-for="(item, index) in message.attachments" 
            :key="index">
            <div 
                class="res_img_wrapper cursor-pointer" 
                v-if="item.is_image">
                <a 
                    :href="path(item.path)" 
                    class="ch_lght">
                    <img 
                        class="img_resp lazyload" 
                        :data-src="path(item.path)" 
                        :alt="item.name" />
                </a>
            </div>
            <div class="file_wrapper" v-else>
                <a 
                    class="doc_file_message" 
                    target="_blank" 
                    download 
                    :href="path(item.path)">
                    <a-icon type="file" class="mr-2" />
                    {{item.name ? item.name : $t('file')}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        message: Object
    },
    computed: {
        ...mapState({
            config: state => state.config.config
        }),
    },
    methods: {
        path(path) {
            if(this.config?.chat_setting?.fix_file_path) {
                if(path.includes('chat_attachments')) {
                    return path
                } else {
                    return path + encodeURIComponent(`&chat_uid=${this.message.chat_uid}&message_uid=${this.message.message_uid}&target=chat_attachments`)
                }
            } else
                return path
        }
    }
}
</script>

<style lang="scss" scoped>
.ch_lght{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.message_files{
    .res_img_wrapper{
        img{
            opacity: 0;
            transition: opacity 0.05s ease-in-out;
            &.lazyloaded{
                opacity: 1;
            }
        }
    }
}
</style>